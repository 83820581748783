import {
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Accordion,
  Box,
  HStack,
  Text,
  TabPanel,
  Tabs,
  Tab,
  TabList,
  TabPanels, Button,
} from "@chakra-ui/react";
import { CoachStats } from "../CoachStats";
import { MdOutlineBook, MdOutlineQueryStats } from "react-icons/md";
import { CoachNextTrainings } from "../CoachNextTrainings";
import { HiOutlineRefresh } from "react-icons/hi";

export function AdminCoaches({ coachesList }) {
  return (
    <>
      <Box
        width={{ base: "100%", sm: "100%", md: "80%" }}
        margin="auto"
        maxW="1300px"
      >
        <Box w={{ base: "90%", sm: "90%%", md: "100%" }} m="auto" pb={4}>
          <HStack display="flex" justifyContent="space-between">
            <HStack fontSize={{ base: "xs", sm: "md" }} mt={0.5}>
              <Text fontWeight={400}>TOTAL ENTRENADORES: {coachesList.length-1}</Text>
            </HStack>

            <HStack spacing={-3} mr={-3.5}>
              <Button
                title="No disponible"
                variant="ghost"
                colorScheme="black"
                //onClick={() => setRefresh(!refresh)}
                isDisabled={true}
                size={{ base: "sm", sm: "sm", md: "md" }}
                /* Save events button */
              >
                <HiOutlineRefresh size="1.25em" />
              </Button>
            </HStack>
          </HStack>
        </Box>

        <Accordion allowMultiple>
          {coachesList.map((coach) => {
            if (coach.coachId === 1) {
              return null; // avoids juan carlos
            } else {
              return (
                <>
                  <AccordionItem key={coach.coachId}>
                    <AccordionButton>
                      <HStack
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontWeight={400}
                        spacing={1}
                      >
                        <Text>{coach.coachName}</Text>{" "}
                        {/*<MdChecklist size="1em" style={{ marginTop: '2px' }}/>*/}
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel p={0} pb={1}>
                      <Tabs isFitted isLazy lazyBehavior="keepMounted" mt={1}>
                        <TabList width={"100%"} maxW="1300px" m="auto">
                          <Tab>
                            <MdOutlineQueryStats />
                            <Text
                              display={{ base: "none", sm: "none", md: "flex" }}
                            >
                              &nbsp;Estadísticas
                            </Text>
                          </Tab>
                          <Tab>
                            <MdOutlineBook />
                            <Text
                              display={{ base: "none", sm: "none", md: "flex" }}
                            >
                              &nbsp;Reservas
                            </Text>
                          </Tab>
                        </TabList>

                        <TabPanels>
                          <TabPanel className="p0-tab-panel pb0-tab-panel ">
                            <CoachStats
                              coachId={coach.coachId}
                              coachPayRate={coach.payRate}
                            />
                          </TabPanel>
                          <TabPanel className="p0-tab-panel pb0-tab-panel">
                            <CoachNextTrainings coachId={coach.coachId} />
                          </TabPanel>
                        </TabPanels>
                      </Tabs>
                    </AccordionPanel>
                  </AccordionItem>
                </>
              );
            }
          })}
        </Accordion>
      </Box>
    </>
  );
}
