import {
  Box,
  Text,
  Stack,
  HStack,
  Badge,
  Image,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from "@chakra-ui/react";
import React from "react";
import { FaRegCreditCard } from "react-icons/fa";

export function BonoActivity(props) {
  let statusColor;
  let statusVariant;
  let statusText;
  switch (props.bonoStatus) {
    case "ACCEPTED":
      statusColor = "blue"; // Green color for ACTIVO status
      statusVariant = "solid";
      statusText = "ACTIVO";
      break;
    case "DRAINED":
      statusColor = "red"; // Green color for ACTIVO status
      statusVariant = "outline";
      statusText = "AGOTADO";
      break;
    case "CANCELLED":
      statusColor = "red"; // Red color for CANCELADO status
      statusVariant = "solid";
      statusText = "CANCELADO";
      break;
    case "REJECTED":
      statusColor = "red"; // Red color for CANCELADO status
      statusVariant = "solid";
      statusText = "RECHAZADO";
      break;
    case "EXPIRED":
      statusColor = "red"; // Orange color for EXPIRADO status
      statusVariant = "outline";
      statusText = "CADUCADO";
      break;
    case "INACTIVE":
      statusColor = "blue"; // Orange color for EXPIRADO status
      statusVariant = "outline";
      statusText = "INACTIVO";
      break;
    case "OUTSTANDING":
      statusColor = "yellow"; // Blue color for PENDIENTE status
      statusVariant = "outline";
      statusText = "PENDIENTE";
      break;
    default:
      statusColor = "#000000"; // Default black color for unknown status
      statusVariant = "solid";
  }

  const options = { year: "2-digit", month: "2-digit", day: "2-digit" };

  function formatDate(value) {
    if (value === undefined)
      return props.bonoName === "CUC Day" ? "1 día" : "30 días";
    let date = new Date(value);
    return new Intl.DateTimeFormat("es-ES", options).format(date);
  }

  return (
    <>
      <Box m="auto" w="100%">
        <HStack
          m="auto"
          role={"group"}
          p={4}
          bg="white"
          boxShadow={"sm"}
          spacing={5}
          w="100%"
          cursor={!props.bonoRequest ? "pointer" : "default"}
        >
          <Image
            className="bono-purchased-img"
            src={props.bonoImage}
            alt={props.bonoName + " logo"}
          />

          <Stack m="auto" ml={0} textAlign={"left"}>
            <Text
              fontFamily={"Oswald"}
              fontWeight="500"
              fontSize="2xl"
              color="black"
            >
              {props.bonoName}
            </Text>
            {!props.bonoRequest && (
              <HStack>
                <Badge
                  variant={statusVariant}
                  colorScheme={statusColor}
                  width={"fit-content"}
                  borderRadius={0}
                >
                  {statusText}
                </Badge>
                {props.bonoPaymentStatus === "OUTSTANDING" && (
                  <>
                    <Popover returnFocusOnClose={false} flip={false}>
                      <PopoverTrigger>
                        <Button
                          size="xl"
                          variant="ghost"
                          _hover={{
                            bg: "transparent",
                          }}
                        >
                          <FaRegCreditCard />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent color="black" width="fit-content">
                        <PopoverArrow />
                        <PopoverBody className="allow-select" cursor="auto">
                          <HStack>
                            <Text>
                              Importe pendiente:{" "}
                              <b>{props.bonoTotal.toFixed(2)}€</b>{" "}
                              {parseFloat(props.bonoDiscount) !== 0 &&
                                "(-" + props.bonoDiscount + "%)"}
                            </Text>
                          </HStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </>
                )}
              </HStack>
            )}
            <Stack fontWeight={400} fontSize={"sm"} spacing={-0.5}>
              {props.bonoRequest && (
                <Text>
                  Precio: <b>{props.bonoPrice} €</b>
                </Text>
              )}
              <Text>
                Sesiones:{" "}
                <b>
                  {props.bonoSessions !== -1
                    ? props.bonoSessions
                    : "Ilimitadas"}
                </b>
              </Text>
              <Text></Text>
              <Text>
                {props.bonoStatus === "REJECTED" ? (
                  <>
                    Solicitado: <b>{formatDate(props.bonoCreationDate)}</b>
                  </>
                ) : props.bonoStatus === "INACTIVE" ? (
                  <>
                    Activación: <b>{formatDate(props.bonoActivationDate)}</b>
                  </>
                ) : props.bonoStatus === "OUTSTANDING" ? (
                  <>
                    Solicitado: <b>{formatDate(props.bonoCreationDate)}</b>
                  </>
                ) : (
                  <>
                    Caducidad: <b>{formatDate(props.bonoExpirationDate)}</b>
                  </>
                )}
              </Text>
            </Stack>
          </Stack>
        </HStack>
      </Box>
    </>
  );
}
